import { useState, useEffect } from 'react';
import GLBViewer from './GLBViewer';
// import singleNftImage from "../assets/nft1.jpeg";
import ethLogo from "../assets/ethereum-eth-logo.png";
import TotalSupplyBar from './TotalSupplyBar';
import gaddixModel from "../assets/king-gaddix.glb";
import Web3 from 'web3'; 

const contractAddress = "0xe075CC38544d3c52311951CF99980162fC3dAf91";
const abi = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "IndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "QueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractState",
		"outputs": [
			{
				"internalType": "enum BetrayedOnes.ContractState",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "holdingSince",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_add",
				"type": "address"
			},
			{
				"internalType": "uint8",
				"name": "_v",
				"type": "uint8"
			},
			{
				"internalType": "bytes32",
				"name": "_r",
				"type": "bytes32"
			},
			{
				"internalType": "bytes32",
				"name": "_s",
				"type": "bytes32"
			}
		],
		"name": "isValidAccessMessage",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			},
			{
				"internalType": "uint8",
				"name": "_v",
				"type": "uint8"
			},
			{
				"internalType": "bytes32",
				"name": "_r",
				"type": "bytes32"
			},
			{
				"internalType": "bytes32",
				"name": "_s",
				"type": "bytes32"
			}
		],
		"name": "mintPresale",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mintPublic",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mintReserved",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address payable",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "ownerWithdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "permittedWithdrawals",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "placeholderURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "presaleAllowancePlusOne",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "receiver",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "royaltyAmount",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "addr",
				"type": "address"
			}
		],
		"name": "saleInfo",
		"outputs": [
			{
				"internalType": "uint256[6]",
				"name": "",
				"type": "uint256[6]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "baseURI_",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "contractState_",
				"type": "uint256"
			}
		],
		"name": "setContractState",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newAddress",
				"type": "address"
			}
		],
		"name": "setDummy",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "maxSale",
				"type": "uint256"
			}
		],
		"name": "setMaxSale",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "setPermittedWithdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "placeholderURI_",
				"type": "string"
			}
		],
		"name": "setPlaceholderURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "presaleAllowance",
				"type": "uint256"
			}
		],
		"name": "setPresaleAllowance",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "recipient",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "setRoyalties",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenPrice_",
				"type": "uint256"
			}
		],
		"name": "setTokenPrice",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "txLimit",
				"type": "uint256"
			}
		],
		"name": "setTxLimit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenPrice",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "tokensOfOwner",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "",
				"type": "uint256[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address payable",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];

const whitelistedAddresses = ["0x31d70ee77bfd82dd621afbb9d32f2dc9f99487ca",
"0x0719b28090bb14abe62a0b6894cce1d0732de3ad",
"0xb0A6033E26C606D1af736B25B599Dc06D2e4FD04",
"0x318cBF186eB13C74533943b054959867eE44eFFE"]
;


const SingleNft = () => {

	const [walletConnected, setWalletConnected] = useState(false);
  	const [wallet, setWallet] = useState('');
	const [contract, setContract] = useState('');
	const [address, setAddress] = useState('');
	const [totalSupply, setTotalSupply] = useState('-');
	const [maxSupply, setMaxSupply] = useState(8888);
	const [price, setPrice] = useState('-');
	const [mintCount, setMintCount] = useState(1);

	if (window.ethereum) {
  		window.ethereum.on("accountsChanged", async function (accounts) {
    			setWallet(accounts[0]);
  		});
	} else {
	  // Handle the case when window.ethereum is undefined
	  alert("To enter the site please connect Metamask extension to your browser");
	}

	//Mint Count function 
	const handleMintCountChange = (action) => {
		if (action === 'increment') {
		  setMintCount(mintCount + 1);
		} else if (action === 'decrement' && mintCount > 0) {
		  setMintCount(mintCount - 1);
		}
	}

	const mint = async () => {

		const networkId = await window.web3.eth.net.getId();
		if (networkId != 1) {
		  await window.ethereum.request({
			method: 'wallet_switchEthereumChain',
			params: [{ chainId: '0x1' }], // chainId must be in hexadecimal numbers
		  });
		}

		const contractState = await contract.methods.contractState().call()
		console.log(contractState)

		if (contractState == 1) {
			if (whitelistedAddresses.includes(wallet)) {
				if (parseInt(totalSupply) >= 3000) {
					alert("No more NFTs to be sold in Presale!");
					return
				}

				var message = await window.web3.utils.soliditySha3(contractAddress, wallet);
				var sign = await window.web3.eth.accounts.sign(message, '603c13734233792745d50a6c9c0a55a075ad8b919d3c57d024e72a98a2d86353');

				var r = sign['r'];
				var s = sign['s'];
				var v = sign['v'];

				var tokenPrice = await window.web3.utils.toWei(price);

				const gas = await contract.methods
					.mintPresale(mintCount, v, r, s)
					.estimateGas(
						{ from: wallet, value: tokenPrice * mintCount},
						function (error, gasAmount) {
						if (error) {
							alert(error.message);
							return;
						}
						}
				);
				const method = await contract.methods
					.mintPresale(mintCount, v, r, s)
					.send(
						{ from: wallet, value: tokenPrice * mintCount, gas: gas },
						function (error, gasAmount) {
						if (error) {
							alert(error.message);
							return;
						}
						}
				);
				if (method) {
					alert("You have minted successfully!")
				}
				loadData(wallet);
			}
			else {
				alert("Wallet is not whitelisted!");
			}
		}
		else if (contractState == 2) {
			var tokenPrice = await window.web3.utils.toWei(price);

				const gas = await contract.methods
					.mintPublic(mintCount)
					.estimateGas(
						{ from: wallet, value: tokenPrice * mintCount },
						function (error, gasAmount) {
						if (error) {
							alert(error.message);
							return;
						}
						}
				);
				const method = await contract.methods
					.mintPublic(mintCount)
					.send(
						{ from: wallet, value: tokenPrice * mintCount, gas: gas },
						function (error, gasAmount) {
						if (error) {
							alert(error.message);
							return;
						}
						}
				);
				if (method) {
					alert("You have minted successfully!")
				}
				loadData(wallet);
		}
		else {
			alert("Sale is not Open!");
		}
	}
 
	const connectMetamask = async () => {
		if (window.ethereum) {
		  window.web3 = new Web3(window.ethereum);
		  await window.ethereum.request({ method: 'eth_requestAccounts' });
		} else if (window.web3) {
		  window.web3 = new Web3(window.web3.currentProvider);
		} else {
		  alert(
			'Non-Ethereum browser detected. You should consider trying MetaMask!'
		  );
		}
	
		const accounts = await window.web3.eth.getAccounts();
		console.log(accounts);
		setWallet(accounts[0]);
		setWalletConnected(true);
	
		const networkId = await window.web3.eth.net.getId();
		if (networkId != 1) {
		  await window.ethereum.request({
			method: 'wallet_switchEthereumChain',
			params: [{ chainId: '0x1' }], // chainId must be in hexadecimal numbers
		  });
		}
	
		await loadData(accounts[0]);
	};

	const loadData = async (wallet) => {
		const contract = new window.web3.eth.Contract(
		  abi,
		  contractAddress
		);
		setContract(contract);

		const totalSupply = await contract.methods.totalSupply().call()
		setTotalSupply(totalSupply)
		var price = await contract.methods.tokenPrice().call()
		price = await window.web3.utils.fromWei(price);
		setPrice(price)
	};


return (
<main class="flex items-center  w-full h-full single-nft">
	<div class="card-container pt-16 grid grid-cols-2 gap-8">
		<div class="flex flex-col justify-start">
			<div class="left flex flex-col w-full object-cover h-4/6 justify-items-start rounded-lg overflow-hidden"
				style={{minHeigth:"320px"}}>
				<GLBViewer
  					src={gaddixModel}
  					alt="Your GLB model"
  					backgroundColor="#191919"
  					autoRotate
  					autoRotateDelay={100}/>

            </div>
			</div>
			<div class="flex flex-col">
				<div class="flex flex-col gap-8">
					<h1 class="capitalize text-2xl font-extrabold text-white">BetrayedOnes NFT</h1>
				
					<p class="text-base text-gray-500	">Immerse yourself into the exciting world of Galacticon with your playable NFT characters! The NFT collection consists of <b>8888 Token</b>, generated out of 24 unique handmade characters with different skinsets.</p>
					<p class="text-base text-gray-500	"><b>1.) Connect your wallet:</b> Click "Connect Wallet", You need to have enough ETH in your wallet to cover 'Max gas fees' + mint cost.<br />
<b>2.) Choose how many you would like to mint:</b> You can mint multiple times up to the maximum amount.<br />
<b>3.) Confirm Transaction:</b> Confirm the transaction in your wallet.</p>
					<div className="md:flex items-center border-t">

                        <div className="mt-7 mr-7 w-full">
                        <p className="text-gray-500 mb-6">Mint Price</p>
                           <span className="flex items-center mt-2 eth-logo">     <img src={ethLogo} alt="super eth" />
                           <p className="text-white text-xl  ml-2">{price}ETH</p>
</span> 
                        </div>
						<div className="mt-7 s-progress-bar w-full">
                        <p className="text-gray-500 mb-6">Supply</p>
						<div className='w-full flex items-center'>
						<TotalSupplyBar percent={(totalSupply * 100) / maxSupply} totalSupply={maxSupply}/>
                           <p className="text-white text-xl  ml-2">{totalSupply}/{maxSupply}</p>
						</div>
                      

                        </div>
                    </div>

					<div class="flex items-center gap-4 my-6 cursor-pointer justify-between">
					<div className='flex gap-[15px] items-center'>
            <button className={`bg-green w-[57px] h-[59px] rounded-[15px] text-white text-[18px] font-bold flex justify-center items-center btn-count ${mintCount === 0 && `btn-count--down`}`} onClick={() => handleMintCountChange("decrement")}>
              -
            </button>
            <span className='font-bold text-xl text-white'>{mintCount}</span>
            <button className='bg-grey w-[57px] h-[59px] rounded-[15px] text-black text-[18px] font-bold flex justify-center items-center bg-white-600 btn-count btn-count--up' onClick={() => handleMintCountChange("increment")}>
              +
            </button>
          </div>
						{!walletConnected && <button class="bg-white-600 px-5 py-3 text-white rounded-lg w-2/4 text-center" onClick={connectMetamask}> Connect </button>}
						{walletConnected && <button class="bg-white-600 px-5 py-3 text-white rounded-lg w-2/4 text-center" onClick={mint}> Buy </button>}
					</div>
				</div>
			</div>
		</div>
</main>
)
}
export default SingleNft
