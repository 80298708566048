import "./scss/index.scss";
import {
  Clients,
  Footer,
  Free,
  Home,
  Navbar,
  ScrollToTop,
  Mint,
  SuperRare,
} from "./components";
import { useEffect, useState } from "react";
import scrollreveal from "scrollreveal";
import SingleNft from "./components/SingleNft";
import Team from "./components/Team";

function App() {
  const [theme, setTheme] = useState("dark");

  const changeTheme = () => {
    theme === "dark" ? setTheme("light") : setTheme("dark");
  };

  useEffect(() => {
    const registerAnimations = () => {
      const sr = scrollreveal({
        origin: "bottom",
        duration: 2000,
        reset: false,
      });
      sr.reveal(
        `
      nav, .home, .free, .clients, .super-rare, .signup, footer
      `,
        { interval: 500 }
      );
    };
    registerAnimations();
  }, []);

  window.setTimeout(() => {
    const home = document.getElementsByClassName("home");
    home[0].style.transform = "none";

    const nav = document.getElementsByTagName("nav");
    nav[0].style.transform = "none";
  }, 1500);

  return (
    <div className="App" data-theme={theme}>
        <ScrollToTop />

        <main className="container max-w-7xl mx-auto">
        <Navbar changeTheme={changeTheme} currentTheme={theme} />
        <SingleNft />
	<br></br>
	<br></br>
	<br></br>
        <Home />
        {/*<Free />*/}
        <br></br>
        <Footer />
        {/*<SuperRare />
        <Team />
        <Mint />
        /*<Footer />*/}
        </main>

    
    
    </div>
  );
}

export default App;
