import { footerLinks } from "../data";
import logo from "../assets/logo.png";
import { BsFacebook, BsTwitter, BsInstagram } from "react-icons/bs";
import { FaTiktok, FaDiscord } from "react-icons/fa";
import { SiOpensea } from "react-icons/si";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="upper">
        <div className="brand-container">
          <img src={logo} alt="logo" style={{ maxWidth: '250px' }} />
          <p>The BetrayedOnes NFT Collection</p>
          <p>Our mission is to create an action-adventure RPG on PC, <br />
	embracing web3 technology to revolutionize the world of gaming</p>
          <ul>
	  <li>
	    <a href="https://twitter.com/BetrayedonesNFT">
	      <BsTwitter />
	    </a>
	  </li>
            <li>
	    <a href="https://www.instagram.com/betrayedonesnft">
              <BsInstagram />
	    </a>
            </li>
            <li>
	    <a href="https://discord.gg/betrayedones-io">
              <FaDiscord />
	    </a>
            </li>
            <li>
	    <a href="https://opensea.io/collection/betrayedonesnft">
              <SiOpensea />
	    </a>
            </li>
          </ul>
        </div>
<div className="links">
  {footerLinks.map(({ title, data }, index) => (
    <div className="link" key={index}>
      <h4>{title}</h4>
      <ul>
        {data.map(({ name, url }, index2) => (
          <li key={index2}>
            <a href={url}>{name}</a>
          </li>
        ))}
      </ul>
    </div>
  ))}
</div>
      </div>
      <div className="lower">
        <span>BetrayedOnes NFT Mint Page &copy; Copyright 2023</span>
        <span>All rights reserved</span>
      </div>
    </footer>
  );
};

export default Footer;
