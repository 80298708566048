import React from "react";
import home from "../assets/nft1.jpeg";

const Home = () => {
  return (
    <div className="home" id="home">
      <div className="container">
        <div className="left">
          <p className="sub-title">Mint Open Now!</p>
          <h1 className="title">The BetrayedOnes NFT collection</h1>
          <p className="description">
            Are you brave enough to enter Galacticon? Get your NFT and it's perks now!
          </p>

        </div>
        <div className="right">
          <div className="image">
            <img src={home} alt="home" />
          </div>
          <div className="ellipse-container">
            <div className="ellipse pink"></div>
            <div className="ellipse orange"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
