import React from 'react';
import '@google/model-viewer';

const GLBViewer = ({ src, alt, backgroundColor, autoRotate, autoRotateDelay }) => {
  return (
    <model-viewer
      src={src}
      alt={alt}
      style={{ width: '100%', height: '100vh' }}
      backgroundColor={backgroundColor}
      auto-rotate={autoRotate}
      auto-rotate-delay={autoRotateDelay}
      camera-controls
      min-polar-angle="1.5708"
      max-polar-angle="1.5708"
    ></model-viewer>
  );
};

export default GLBViewer;
