

const TotalSupplyBar = ({percent, totalSupply}) => {
    return (
        <div className="w-full">
  <div className="shadow w-full bg-grey-light">
    <div className="bg-blue text-xs leading-none py-1 text-center text-white" style={{width:`${percent}%`}}></div>
  </div>
  </div>
)
}
export default TotalSupplyBar