import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { ImSun } from "react-icons/im";
import { BsFillMoonFill } from "react-icons/bs";
import logo from "../assets/logo.png";

const Navbar = ({ changeTheme, currentTheme }) => {
  const [navState, setNavState] = useState(false);

  const handleOpenSideBar = (state) => {
    if(state === "open"){
      document.body.style.overflow = "hidden"
      setNavState(true)
    } else {
      document.body.style.overflow = "scroll"
      setNavState(false)
    }
  }
  return (
    <nav>
      <div className="brand-container">
          <img src={logo} alt="logo" style={{ maxWidth: '250px' }} />
        <div className="toggle-container">
          <div className="toggle">
            {navState ? (
              <MdClose onClick={() => handleOpenSideBar(false)} />
            ) : (
              <GiHamburgerMenu onClick={() => handleOpenSideBar("open")} />
            )}
          </div>
          {/* DARK/WHITE MODE DISABLED */}
          {/* <div className="mode">
            {currentTheme === "dark" ? (
              <ImSun className="light" onClick={changeTheme} />
            ) : (
              <BsFillMoonFill className="dark" onClick={changeTheme} />
            )}
          </div> */}
        </div>
      </div>
      <div className={`links-container ${navState ? "nav-visible" : ""}`}>
        <ul className="links">
          <li>
            <a href="https://betrayedones.io/" onClick={() => setNavState(false)}>
              Home
            </a>
          </li>
          <li>
            <a href="https://betrayedones.io/blog/" onClick={() => setNavState(false)}>
              About
            </a>
          </li>
          <li>
            <a href="https://betrayedones.io/faq/" onClick={() => setNavState(false)}>
              FAQ
            </a>
          </li>

                    {/* DARK/WHITE MODE DISABLED */}

          {/* <li onClick={changeTheme}>
            {currentTheme === "dark" ? (
              <ImSun className="light" />
            ) : (
              <BsFillMoonFill className="dark" />
            )}
          </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
