import super1 from "./assets/nft2.jpeg";
import super2 from "./assets/nft4.jpg";
import super3 from "./assets/nft-5.jpg";
import super4 from "./assets/nft-6.jpg";
import release2 from "./assets/nft-7.jpg";

export const freeNFTData = [
  {
    image: super1,
    series: "BetrayedOnes",
    title: "Xexi #1",
    price: 0.15,
    tag: 7510,
    time: 1,
  },
  {
    image: release2,
    series: "BetrayedOnes",
    title: "Zec #1",
    price: 0.15,
    tag: 8197,
    time: 1,
  },
];

export const superRareData = [
  {
    image: super1,
    series: "BetrayedOnes",
    title: "Zec #1",
    price: 0.15,
    tag: 8197,
    time: 1,
  },
  {
    image: super2,
    series: "BetrayedOnes",
    title: "Xexi #1",
    price: 0.15,
    tag: 7510,
    time: 1,
  },
  {
    image: super3,
    series: "BetrayedOnes",
    title: "Gaddix #10",
    price: 0.15,
    tag: 2790,
    time: 1,
  },
  {
    image: super4,
    series: "BetrayedOnes",
    title: "Ares #1",
    price: 0.15,
    tag: 810,
    time: 1,
  },
];


export const footerLinks = [
  {
    title: "About",
    data: [
      { name: "Privacy", url: "https://betrayedones.io/privacy-policy/" },
      { name: "Terms", url: "https://betrayedones.io/term-condition/" },
      { name: "Roadmap", url: "https://betrayedones.io/" },
    ],
  },
  {
    title: "Social",
    data: [
      { name: "Twitter", url: "https://twitter.com/BetrayedonesNFT" },
      { name: "Opensea", url: "https://opensea.io/collection/betrayedonesnft" },
      { name: "Instagram", url: "https://www.instagram.com/betrayedonesnft" },
      { name: "Discord", url: "https://discord.gg/betrayedones-io" }
    ],
  },
];
